<template>
  <ws-dialog
      v-if="display"
      v-model="display"
      :title="$t('ChatDetails')"
      :show-save="!displayParticipants"
      :cancel-text="$t('Close')"
      show-delete
      @save="editDynamicChat"
      :confirm-delete-text="$t('DeleteChatConfirmation')"
      :confirm-delete-code="selectedChat.uuid"
      @delete="deleteChat"
      width="700"
      :display-confirm-delete="deleteOld"
      :close-delete="deleteOld"
  >
    <div style="min-height: 300px">
      <ws-toggle
          v-if="isDynamic"
          @input="handleDisplayEdit"
          v-model="displayParticipants"
          :text-true="$t('ChatParticipants')"
          :text-false="$t('Settings')"
          class="mb-5"
      >
      </ws-toggle>
      <ws-text-field
          v-if="!isDynamic"
          @change="editChatName"
          v-model="entityData.name"
          :label="$t('ChatName')"
          :placeholder="$t('EnterChatName')"
          :error-messages="nameError"
          :error="!!nameError"
          @input="nameError = null"
          :hide-details="false"
          width="100%"
      />
      <v-btn
          v-if="!isDynamic && !displayEditParticipants && displayParticipants"
          @click="displayEditParticipants = true"
          :color="wsACCENT"
          class="noCaps d-flex justify-space-between mb-5"
          outlined
          block
      >
        <div class="mr-7" />
        {{ $t('EditChatParticipants') }}
        <v-icon class="mr-2">mdi-account-multiple-check</v-icon>
      </v-btn>
      <div v-else-if="displayEditParticipants" class="mb-5">
        <v-row>
          <v-col>
            <v-btn class="noCaps" block text @click="displayEditParticipants = false" :color="wsACCENT">
              {{ $t('Cancel') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                @click="saveChatParticipants"
                class="noCaps" block dark  :color="wsATTENTION" elevation="0">
              {{ $t('Save') }}
            </v-btn>
          </v-col>
        </v-row>

      </div>
      <ws-data-table
          v-if="displayParticipants"
          :items="chatUsersFiltered"
          :headers="headers"
      >
        <template #item.user="{item}">
          <div class="d-flex align-center text-no-wrap">
            <v-avatar class="mr-2 ml-1"  :color="wsACCENT"
                      size="36" key="profile_picture" >
              <v-img v-if="item.img" :src="item.img">
                <template slot="placeholder">
                  <v-img src="@/assets/placeholder/user_blank.jpg" />
                </template>
              </v-img>
              <v-icon  dark v-else>mdi-account-tie</v-icon>
            </v-avatar>
            <div>
              <h5 :style="`color : ${wsACCENT}`">{{ item.user || item.name }} </h5>
              <h6 v-if="item.uuid === selectedChat.admin" class="font-weight-regular"> {{ $t('Owner') }}</h6>
            </div>
          </div>
        </template>

        <template #item.selected="{item}">
          <div class="d-flex justify-end">
            <ws-check-box
                v-if=" item.uuid !== selectedChat.admin"
                v-model="item.selected" :small="false" />
          </div>
        </template>

      </ws-data-table>
      <div v-else>

        <ws-text-field
            v-model="entityData.name"
            :label="$t('ChatName')"
            :placeholder="$t('EnterChatName')"
            :error-messages="nameError"
            :error="!!nameError"
            @input="nameError = null"
            :hide-details="false"
            width="100%"
        />

        <h5 style="color : #4F5A6E; font-size: 13px; font-weight: 500 " >  {{  $t('Course_')  }} </h5>
        <h4 v-if="selectedCourse.text" class="mt-1">{{ selectedCourse.text }}</h4>
        <v-row class="mt-1" >
          <v-col  v-if="selectedCourse.payed && selectedCourse.has_payment_plans"  cols="6" >
            <ws-select
                v-model="entityData.payment_plan_id"
                :items="selectedCourse.payment_plans_select || [] "
                :label="$t('PaymentPlan')"
                clearable
            />
          </v-col>
          <v-col v-if="selectedCourse.online && selectedCourse.scheduled"  cols="6" >
            <ws-select
                v-model="entityData.enrollment_wave_id"
                :items="selectedCourse.enrollemnt_waves_select || [] "
                :label="$t('EnrollmentWave')"
                clearable
            />
          </v-col>
          <v-col v-if="selectedCourse.has_groups"  cols="6" >
            <ws-select
                v-model="entityData.course_group_id"
                :items="selectedCourse.groups_select || [] "
                :label="$t('Group')"
                clearable
            />
          </v-col>

        </v-row>

        <h4 class="mt-3">{{ $t('Managers') }}</h4>

        <ws-data-table
            class="mt-3"
            v-if="managersSelect.length > 0"
            :items="managersSelect"
            :headers="headers"
        >

          <template #item.user="{item}">
            <div class="d-flex align-center">
              <v-avatar size="40" :color="wsLIGHTACCENT" class="mr-3">
                <v-img v-if="item.img" :src="item.img"></v-img>
                <v-icon v-else  dark>mdi-account</v-icon>
              </v-avatar>
              <div>
                <h5 >{{ $t(item.name) }}</h5>
              </div>
            </div>
          </template>
          <template #item.selected="{item}">
            <div class="d-flex justify-end">
              <ws-check-box v-model="item.selected" :small="false" />
            </div>
          </template>
        </ws-data-table>




      </div>
    </div>

  </ws-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "editChatDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    deleteOld : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      chatUsers : [],
      contacts : [],
      displayParticipants : true,
      displayEditParticipants : false,
      entityData : {},
      nameError : null,
      managersSelect : [],
      coursesSelect : []
    }
  },
  computed : {
    ...mapState('messenger', [
      'selectedChatId',
      'selectedChat',
    ]),

    selectedCourse() {
      if ( this.coursesSelect.length === 0 ) {
        return {}
      }
      let course = this.coursesSelect.find(el => el.value === this.entityData.course_id)
      if (!course ) {
        return {}
      }
      return course
    },

    selectedUsers() {
      if ( this.chatUsersFiltered.length === 0 ) {
        return []
      }
      let selectedUsers = this.chatUsersFiltered.filter(el => el.selected )
      if ( selectedUsers.length === 0 ) {
        return []
      }
      return selectedUsers.map( el=> el.uuid)

    },
    selectedManagers() {
      if ( this.managersSelect.length === 0 ) {
        return []
      }
      let selectedManagers = this.managersSelect.filter(el => el.selected )
      if ( selectedManagers.length === 0 ) {
        return []
      }
      return selectedManagers.map( el=> el.id)
    },

    chatUsersFiltered() {
      if ( this.chatUsers.length === 0 ) {
        return []
      }
      let items = this.chatUsers
      let usersIds = this.chatUsers.map( el=> el.uuid )
      console.log('usersIds' , usersIds)
      if ( this.displayEditParticipants && this.contacts.length > 0 ) {
        let freeContacts = this.contacts.filter(el=> !usersIds.includes(el.uuid) )
        console.log('freeContacts' , freeContacts)
        items = items.concat(freeContacts)
      }

      return items
    },

    isDynamic() {
      return this.selectedChat.type === 'dynamic_chat'
    },
    headers() {
      let items  =  [
        { text : this.$t('name') , value : 'user' }
      ]
      if ( this.displayEditParticipants || !this.displayParticipants ) {
        items.push( { text  : this.$t('Selected') ,value : 'selected' , width : '10px' } )
      }
      return items
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display) {
        this.$emit('input' , this.display)
      }
    },
  },
  methods : {
    ...mapActions('courses',   [ 'GET_COURSES_SELECT' ]),
    ...mapActions( 'businessUsers', [ 'GET_MANAGERS_SELECT' ] ),
    ...mapActions('messenger', [
      'GET_CHAT_USERS',
      'GET_CONTACT_BOOK',
      'EDIT_CHAT',
      'DELETE_CHAT'
    ] ),


    async editDynamicChat() {

      let data = {
        uuid : this.entityData.uuid,
        name : this.entityData.name
      }

      let chatUsersUuids = []
      if ( this.chatUsers.length > 0 ) {
        chatUsersUuids =  this.chatUsers.map(el => el.uuid )
      }
      let currentManagersUuids = []
      this.managersSelect.forEach(el => {
        if ( chatUsersUuids.includes(el.id) ) {
          currentManagersUuids.push(el.id)
        }
      })

      let addManagerList = []
      let deleteManagerList = []

      this.selectedManagers.forEach(el => {
        if ( !currentManagersUuids.includes(el) ) {
          addManagerList.push(el)
        }
      })
      currentManagersUuids.forEach(el => {
        if ( !this.selectedManagers.includes(el) ) {
          deleteManagerList.push(el)
        }
      })

      if ( addManagerList.length > 0 ) {
        data.add_managers = addManagerList
      }
      if ( deleteManagerList.length > 0 ) {
        data.delete_managers = deleteManagerList
      }

      let result = await this.EDIT_CHAT(data)

      if ( !result ) {
        return
      }

      this.selectedChat.name = this.entityData.name
      this.selectedChat = this.COPY(this.selectedChat)
      this.displayParticipants = true
      this.notify(this.$t('ChangesSaved'))
    },
    async editChatName() {
      let data = {
        uuid : this.selectedChat.uuid,
        name : this.entityData.name
      }
      let result = await this.EDIT_CHAT(data)

      if ( !result ) {
        return
      }
      this.selectedChat.name = this.entityData.name
      this.selectedChat = this.COPY(this.selectedChat)
      this.notify(this.$t('ChangesSaved'))
    },
    async deleteChat() {
      let result = await this.DELETE_CHAT(this.selectedChat.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }

      let index = this.$store.state.messenger.activeChats.findIndex(el => el.uuid === this.selectedChat.uuid)

      if ( index !== -1 ) {
        this.$store.state.messenger.activeChats.splice(index , 1)
      }

      this.$store.state.messenger.selectedChatId = null
      this.$store.state.messenger.selectedChat = {}

      this.$emit('input' , false)
      this.notify(this.$t('ChatDeleted'))

    },
    handleDisplayEdit(value) {
      if ( !value ) {
        this.entityData = this.COPY(this.selectedChat)
      }
    },
    async saveChatParticipants() {

      let data = {
        uuid : this.selectedChat.uuid,
        users : this.selectedUsers
      }
      let result = await this.EDIT_CHAT(data)

      if ( !result ) {
        return
      }

      this.initPage()
      this.displayEditParticipants = false

    },

    async openChatUsers() {
      this.chatUsers = []
      let result = await this.GET_CHAT_USERS(this.selectedChatId)
      this.displayChatUsers = true
      if ( !result || result === true ) {
        return
      }
      result.forEach((contact) => {
        contact.selected = true
      })
      this.chatUsers = result
    },

    async getManagersSelect() {

      let result = await this.GET_MANAGERS_SELECT()
      if ( !result || result === true ) {
        return
      }
      let chatUsersUuids = []
      if ( this.chatUsers.length > 0 ) {
        chatUsersUuids = this.chatUsers.map(el => el.uuid)
      }
      result.forEach((manager) => {
        manager.selected = chatUsersUuids.includes( manager.id )
      })

      this.managersSelect = this.COPY(result)

    },
    async getContactsSelect() {
      let result = await this.GET_CONTACT_BOOK()
      if (!result || result === true ) {
        return
      }
      result.contacts.forEach((contact) => {
        contact.selected = false
      })
      this.contacts = this.COPY(result.contacts)
    },
    async getCoursesSelect() {
      let result = await this.GET_COURSES_SELECT();
      if ( !result || result === true ) {
        return
      }
      this.coursesSelect = result
    },

    initPage() {
      this.openChatUsers()
      if ( !this.isDynamic ) {
        this.entityData.name = this.COPY(this.selectedChat.name)
        this.getContactsSelect()
      } else {
        this.getManagersSelect()
        this.getCoursesSelect()
      }
      this.display = this.value || false
    }


  },
  mounted() {
   this.initPage()
  }
}
</script>

<style scoped>

</style>