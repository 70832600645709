<template>
  <!-- Chats Window -->
  <v-sheet
      :width="!SM ? '370' : '100%'"
      :min-width="!SM ? '370' : '100%'"
      class="fill-height"
      :style="`border-right : 1px solid ${wsBACKGROUND} !important`"
  >

    <v-row class="no-gutters flex-wrap flex-column fill-height " >

      <!-- Chats Header -->
      <v-col cols="auto" class="shrink pa-5"  >

        <div class="d-flex align-center" :class="SM ? 'mt-2' : '' ">
          <ws-text-field
              v-model="$store.state.messenger.search"
              :placeholder="$t('Search')"
              append-icon="mdi-magnify"
              clearable
              width="100%"
          />
          <ws-tooltip :text="$t('AddChat')">
            <v-btn
                v-if="sidebarType === 'active_chats' && ( IS_BUSINESS_OWNER || IS_BUSINESS_MANAGER) "
                @click="openAddChat"
                :color="wsATTENTION"
                class="noCaps ml-3"
                large icon
            >
              <v-icon large>mdi-chat-plus</v-icon>
            </v-btn>
          </ws-tooltip>

        </div>

      </v-col>
      <!-- Chats Content -->
      <v-col cols="auto" class="grow overflow-y-auto " style="height: 50px"  >

        <v-fade-transition mode="out-in">
          <sidebarActiveChats     key="active_chats" v-if="sidebarType === 'active_chats' " />
          <sidebarContactBook     key="contact_book" v-else-if="contactBookDisplayCondition" />
          <sidebar-manager-list   key="managers_list" v-else-if="sidebarType === 'managers_list' "/>
          <sidebar-manager-chats  key="managers_list" v-else-if="sidebarType === 'manager_chats' " />
        </v-fade-transition>

      </v-col>

      <!-- Chats Footer -->
      <v-col cols="auto" class="shrink pa-5"  :class="[{'pr-16': SM}]" >

        <ws-button-group
            v-model="$store.state.messenger.sidebarType"
            :items="sideBarTypesSelect"
        />

      </v-col>
    </v-row>

    <new-chat-dialog
        v-if="displayAddChat"
        v-model="displayAddChat"
    />

  </v-sheet>
</template>

<script>

import sidebarActiveChats from "@/modules/messenger/components/UI/sidebarActiveChats";
import sidebarContactBook from "@/modules/messenger/components/UI/sidebarContactBook";
import newChatDialog from "@/modules/messenger/components/UI/newChatDialog";
import sidebarManagerList from "@/modules/messenger/components/UI/sidebarManagerList";
import sidebarManagerChats from "@/modules/messenger/components/UI/sidebarManagerChats";
import {mapMutations, mapState} from "vuex";

export default {
  name: "messengerSidebar",
  props : {
    compact : {
      type : Boolean,
      default : false
    }
  },
  components : {
    sidebarActiveChats,
    sidebarContactBook,
    newChatDialog,
    sidebarManagerList,
    sidebarManagerChats
  },
  data() {
    return {
      displayAddChat : false,
      sideBarTypesSelect : [],
    }
  },
  computed : {
    ...mapState('messenger', [
          'sidebarType',
          'chatsDisabled',
          'selectedContactId'
    ]),

    contactBookDisplayCondition() {
        if ( this.compact ) {
          return this.sidebarType === 'contact_book' && !this.selectedContactId
        }
        return this.sidebarType === 'contact_book'
    }
  },
  methods : {
    ...mapMutations('messenger', [
        'SET_MESSENGER_SIDEBAR_TYPE',
    ]),

    openAddChat() {
      this.displayAddChat = true
    },
  },
  beforeMount() {
    this.sideBarTypesSelect = [
      { text : this.$t('Chats')    , value : 'active_chats'    , translate : true },
      { text : this.$t('Contacts') , value : 'contact_book'    , translate : true }
    ]
    if ( this.IS_BUSINESS_OWNER ) {
      this.sideBarTypesSelect.push({ text : this.$t('Managers') , value : 'managers_list' , tooltip : this.$t('ManagersChatsControlTooltip') , tooltip_top : true })
    }
  }

}
</script>

<style scoped>

</style>