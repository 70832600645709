<template>
  <div  class="d-flex justify-space-between">
    <div class="d-flex mb-3">

      <!-- USER AVATAR -->
      <v-avatar class="mr-3" v-if="displayUserInfo" size="32" :color="wsLIGHTACCENT">
        <v-img v-if="message.sender_img" :src="message.sender_img"></v-img>
        <v-icon v-else dark>mdi-account</v-icon>
      </v-avatar>

      <v-sheet
          @contextmenu="openContextMenu"
          :color="message.sender === $store.state.auth.user.id ? wsLIGHTCARD :  wsBACKGROUND"
          :class="[{'ml-11' : !displayUserInfo }]"
          class="wsRoundedLight pa-2"
      >
        <!-- USER NAME -->
        <div v-if="displayUserInfo" class="d-flex justify-space-between">
          <h5  class="mr-3"
              :style="`color : ${wsDARKER}`">
            {{ message.sender_name }}
          </h5>
          <v-icon
              v-if="selectedChat.type === 'personal' && message.sender === $store.state.auth.user.id "
              x-small :color="isRead ? wsDARKER : 'grey'" >
            mdi-eye
          </v-icon>
        </div>

        <!-- REPLY TO MESSAGE -->
        <div v-if="message.reply_to_id && replyMessage.uuid" class="mb-3">
          <div class="d-flex align-center mt-1">
            <v-img v-if="replyMessage.type === 'image'" height="48" width="48" :src="replyMessage.file" class="wsRoundedLight mr-3" />
            <v-divider :style="`border-color : ${wsDARKLIGHT}`" class="mr-3" vertical />
            <div>
              <h6 :style="`color : ${wsACCENT}`" > {{ replyMessage.sender_name }} </h6>
              <h6 v-if="replyMessage.type === 'text'" class="font-weight-regular" v-html="replyMessage.text" />
              <h6 v-if="replyMessage.type === 'image'" class="font-weight-regular" > {{ $t('Image') }} </h6>
            </div>
          </div>
        </div>

        <!-- Text message -->
        <div v-if="message.type === 'text'"
             class="d-flex justify-space-between">
          <h5 v-html="contentText"
              class="font-weight-regular mr-3"
              style="white-space: pre-wrap; word-break: break-all; word-wrap: break-word"
          />
          <v-icon
              v-if="selectedChat.type === 'personal' && message.sender === $store.state.auth.user.id && !displayUserInfo "
              x-small :color="isRead ? wsDARKER : 'grey'" >
            mdi-eye
          </v-icon>
        </div>
        <!-- Image message -->
        <v-img v-if="message.type === 'image'"
               @click="displayImage = true"
               :src="message.file"
               class="wsRoundedLight pointer"
               :max-width="!SM ? '300' : '150'"
        />

        <!-- Message details -->
        <div class="d-flex justify-space-between">
          <!-- Time -->
          <h6 :style="`color : ${wsDARKLIGHT}`" class="mr-3">
            {{ PARSE_TIME_STAMP(message.date_created, true ) }}
          </h6>
          <!-- Details -->
          <div class="d-flex">
            <!-- Is Edited -->
            <h6 class="mr-3" v-if="message.is_edited " :style="`color : ${wsDARKLIGHT}`"> {{ $t('Edited') }}</h6>
            <!-- Actions -->
            <ft-select
                @input="messageAction"
                :items="editActionsSelect"
                close-on-click
            >
              <v-icon v-ripple class="pointer" style="margin-top: 3px" :color="wsDARKLIGHT" x-small>
                mdi-dots-horizontal
              </v-icon>
            </ft-select>
          </div>
        </div>


      </v-sheet>

    </div>

    <!-- DIALOGS-->

    <!-- View Image-->
    <v-dialog
        v-if="message.type === 'image' && displayImage"
        v-model="displayImage"
        fullscreen
    >
      <v-sheet dark class="fill-height d-flex align-center justify-center">
        <v-btn
            style="position:fixed; top: 15px ; right: 15px; z-index : 2; min-width: 40px; border-radius: 50%"
            height="40"
            class="px-0"
            @click="displayImage = false"
            dark>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-img :src="message.file" height="100%" width="100%" contain />
      </v-sheet>


    </v-dialog>
    <!-- Delete Message-->
    <ws-dialog
        v-model="displayDeleteDialog"
        @delete="deleteMessage"
        close-delete
        display-confirm-delete

    ></ws-dialog>
    <!-- Context Menu -->
    <ft-select
        @input="messageAction"
        :items="editActionsSelect"
        :expanded="displayContextMenu"
        @expand="displayContextMenu = $event"
        :x="x"
        :y="y"
        absolute
        close-on-click
    />

  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "chatMessage",
  props : {
    message : {
      type : Object,
      default() { return {} }
    },
    index : {
      type : Number ,
      default : 0
    }
  },
  data() {
    return {
      displayImage : false,
      displayDeleteDialog : false,
      displayContextMenu : false,
      x : 0,
      y : 0,
    }
  },
  computed : {
    ...mapState('messenger', [
      'selectedChatId',
      'selectedChat',
      'chatMessages',
      'selectedMessageContext'
    ]),
    isRead() {
      if ( !this.message.read_by ) {
        return false
      }
      if ( this.message.read_by.length === 0  ) {
        return false
      }
      if ( this.message.read_by.length === 1 && this.message.read_by[0] === this.message.sender  ) {
        return false
      }
      return true
    },
    replyMessage() {
      if (this.chatMessages.length === 0 ) {
        return {}
      }
      return this.chatMessages.find(el => el.uuid === this.message.reply_to_id) || {}
    },
    editActionsSelect() {


      let items = [
        { text : this.$t('Reply') , value : 'reply' },
      ]
      if ( this.$store.state.auth.user.id === this.message.sender && this.message.type !== 'image' ) {
        items.unshift({ text : this.$t('Edit') , value : 'edit' } )
      }

      if ( this.message.sender === this.$store.state.auth.user.id || this.IS_BUSINESS_OWNER ) {
        items.push(     { text : this.$t('Delete') , value : 'delete' } )

      }

      return items
    },
    contentText() {
      if ( this.message.type !== 'text') {
        return this.message.text
      }
      const urlRegex = /((https?:\/\/)[^\s]+)/g;
      let text = this.message.text

      return text.replace(urlRegex, (url) => {
        return `<a style="color : ${this.wsDARK}; text-decoration: none" href="${url}" target="_blank"><b>${url}</b></a>`;
      })
    },

    displayUserInfo() {
      if (   this.index === 0) { return true }
      return this.chatMessages[this.index - 1].sender !== this.message.sender;
    }
  },
  watch : {
    selectedMessageContext() {
      if ( this.selectedMessageContext !== this.message.uuid ) {
        this.displayContextMenu = false
      }
    }
  },
  methods : {
    ...mapActions('messenger', [
      'DELETE_MESSAGE',
    ] ),

    openContextMenu($event) {
      this.$store.state.messenger.selectedMessageContext = this.message.uuid
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })
    },
    messageAction(action) {
      switch (action) {
        case 'reply'  : return this.$emit('reply' , this.message)
        case 'edit'   : return this.$emit('edit' , this.index)
        case 'delete' : return this.openDeleteMessage()
      }
    },
    openDeleteMessage() {
      this.displayDeleteDialog = true
    },
    async deleteMessage() {
      let result = await this.DELETE_MESSAGE(this.message.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.displayDeleteDialog = false
      this.$emit('delete' , this.index)
    }
  }

}
</script>

<style scoped>

</style>