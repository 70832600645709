<template>
  <v-sheet color="transparent" class="pb-6">

    <div v-if="groups.length > 0" class="mb-6" :class="!SM ? 'px-6 ' : 'px-3'" >
      <ft-select v-model="selectedGroup" :items="groups">
        <template v-slot:default="{text}">
          <h4 v-if="!SM" class="font-weight-medium">{{  text ? text : $t('AllContacts')   }} <v-icon>mdi-chevron-down</v-icon></h4>
          <v-btn small v-else outlined block class="noCaps" >{{   text ? text : $t('AllContacts')    }} <v-icon small>mdi-chevron-down</v-icon></v-btn>
        </template>

      </ft-select>
    </div>


    <!-- Chats Table -->
    <v-data-table  :items="contactsFiltered"
                   :search="search"
                   :headers="headers"
                   disable-pagination
                   hide-default-footer
                   style="background-color: transparent"
                   hide-default-header >

      <template v-slot:item="{item}" >
        <tr @click="selectChat(item)" class="pointer"
            :style="`background-color: ${selectedContactId === item.uuid ? wsDARK : ''};
                                 color: ${selectedContactId === item.uuid ? 'white' : '' } `" >
          <td width="10px" class="pr-0 py-1">
            <v-avatar size="40" :color="wsLIGHTACCENT">
              <v-img v-if="item.img" :src="item.img"></v-img>
              <v-icon v-else  dark>mdi-account</v-icon>
            </v-avatar>
          </td>
          <td class="pr-6" >
            <div class="d-flex justify-space-between">
              <h5>{{ item.name }}</h5>
<!--              <h5>{{ item.type }}</h5>-->
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "sidebarcontacts",
  data() {
    return {
      contacts : [],
      groups : [],
      selectedGroup : null,
      headers : [
        { value : 'name' }
      ],

    }
  },
  computed : {
    ...mapState('messenger', [
      'search',
      'selectedContactId',
    ]),
    contactsFiltered() {
      let items = this.contacts
      if ( this.selectedGroup !== null ) {
        items = items.filter(el => el.type === this.selectedGroup )
      }
      return items
    }
  },
  methods : {
    ...mapActions('messenger', [ 'GET_CONTACT_BOOK' ] ),

    selectChat(item) {
      this.$store.state.messenger.selectedContactId = item.uuid
      this.$store.state.messenger.selectedContact = item
    },

    async initPage() {

      let result = await this.GET_CONTACT_BOOK()
      if (!result || result === true ) { return  }
      this.contacts = result.contacts

      if (result.groups) {
        this.groups = result.groups
      }

      if ( this.groups.length > 0 ) {
        this.selectedGroup = this.groups[0].value
      }
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>