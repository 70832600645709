<template>
  <div style="width: 100%">

    <v-divider class="mb-4" :style="`border-color : ${wsBACKGROUND}`" />

    <div class="d-flex justify-space-between px-5" style="width: 100%">

      <h4 :style="`color : ${wsACCENT}`" class=" mb-4">
        {{ $t('ManagersChatsControl')}}:
      </h4>

      <ws-tooltip :text="$t('ManagersChatsControlTooltip')">
        <v-icon>mdi-information-outline</v-icon>
      </ws-tooltip>
    </div>

    <v-divider  :style="`border-color : ${wsBACKGROUND}`" />
    <ws-data-table
        v-if="items.length > 0"
        :items="items"
        :headers="headers"
        :search="search"
        :row-action="openManagerChat"
        no-header
        disable-pagination
    >

      <template #item.name="{item}">
        <div class="d-flex align-center">
          <v-avatar class="mr-4" size="40" :color="getChatAvatarColor(item.type)">
            <v-img v-if="item.img" :src="item.img ? item.img : item.chat_img "></v-img>
            <v-icon v-else dark>{{ getChatIcon(item.type) }}</v-icon>
          </v-avatar>
          <div style="width: 100%" >
            <div class="d-flex justify-space-between">
              <h5> {{ item.name }} </h5>
              <v-chip v-if="item.new_messages_count > 0" class="px-3" small dark :color="wsLIGHTACCENT"><h5>{{  item.new_messages_count }} </h5></v-chip>
<!--              <h5>{{  PARSE_TIME_STAMP(item.last_message_date , true) }} </h5>-->
            </div>
<!--            <h5 class="font-weight-regular"> {{ item.last_message || $t('NoMessages') }}</h5>-->
          </div>
        </div>

      </template>

    </ws-data-table>
    <h4 v-else class="py-6 text-center">
      {{ $t('NoChats') }}
    </h4>


  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "sidebarManagerList",
  data() {
    return {
      items : [],
      headers : [
        { value : 'name'}
      ]
    }
  },
  computed : {
    ...mapState('messenger' , [ 'search']),
  },
  methods : {
    ...mapActions('messenger' , ['GET_MANAGERS_LIST']),

    openManagerChat(item) {
      this.$store.state.messenger.selectedChatId = null
      this.$store.state.messenger.selectedChat = {}
      this.$store.state.messenger.selectedManager = this.COPY(item)
      this.$store.state.messenger.selectedManagerId = item.uuid
      this.$store.state.messenger.sidebarType = 'manager_chats'
    },

    getChatAvatarColor(type) {
      switch(type) {
        case 'chat' : return this.wsDARKLIGHT
        case 'dynamic_chat' : return this.wsDARK
        default : return this.wsLIGHTACCENT
      }
    },
    getChatIcon(type) {
      switch(type) {
        case 'chat' : return 'mdi-account-group'
        case 'dynamic_chat' : return 'mdi-bank'
        default : return 'mdi-account'
      }
    },

    async getManagersList() {
      let result = await this.GET_MANAGERS_LIST()
      if ( !result || result === true ) {
        return
      }
      this.items = result
    }
  },
  mounted() {
    this.getManagersList()
  }
}
</script>

<style scoped>

</style>