<template>
  <div>
    <v-divider class="mb-4" :style="`border-color : ${wsBACKGROUND}`" />

    <div class="d-flex align-center px-5 mb-4">
      <v-btn
          :color="wsACCENT"
          @click="$store.state.messenger.sidebarType = 'managers_list'"
          :style="`background-color: ${wsBACKGROUND}`"
          class="mr-6"
          outlined
          small
          icon
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h4 :style="`color : ${wsACCENT}`">
        {{ $t('Manager')}}: {{ selectedManager.name }}
      </h4>

    </div>


    <v-divider  :style="`border-color : ${wsBACKGROUND}`" />

    <ws-data-table
        v-if="items.length > 0"
        :items="items"
        :headers="headers"
        :search="search"
        :row-action="selectChat"
        no-header
        disable-pagination
    >

      <template #item.name="{item}">
        <div class="d-flex">
          <v-avatar class="mr-4" size="40" :color="getChatAvatarColor(item.type)">
            <v-img v-if="item.chat_img" :src="item.chat_img" />
            <v-icon v-else dark>{{ getChatIcon(item.type) }}</v-icon>
          </v-avatar>
          <div style="width: 100%" >
            <div class="d-flex justify-space-between">
              <h5> {{ item.name }} </h5>
              <h5>{{  PARSE_TIME_STAMP(item.last_message_date , true) }} </h5>
            </div>
            <div class="d-flex justify-space-between">
              <h5 class="font-weight-light">{{ (item.last_message_preview.slice(0,25) + '...') || $t('NoMessages') }} </h5>
              <v-chip v-if="item.new_messages_count > 0" class="px-3" small dark :color="wsLIGHTACCENT"><h5>{{  item.new_messages_count }} </h5></v-chip>
            </div>

          </div>
        </div>

      </template>

    </ws-data-table>
    <h4 v-else class="py-6 text-center">
      {{ $t('NoChats') }}
    </h4>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "sidebarManagerChats",
  data() {
    return {
      items : [],
      headers : [
        { value : 'name'}
      ]
    }
  },
  computed : {
    ...mapState('messenger' , ['selectedManager' ,'selectedManagerId' , 'search']),
  },
  watch : {
    selectedManagerId() {
      this.getManagerChats()
    }
  },
  methods : {
    ...mapActions('messenger' , ['GET_MANAGER_CHATS']),

    selectChat(item) {
      this.$store.state.messenger.selectedChatId = item.uuid
      this.$store.state.messenger.selectedChat = this.COPY(item)
    },
    getChatAvatarColor(type) {
      switch(type) {
        case 'chat' : return this.wsDARKLIGHT
        case 'dynamic_chat' : return this.wsDARK
        default : return this.wsLIGHTACCENT
      }
    },
    getChatIcon(type) {
      switch(type) {
        case 'chat' : return 'mdi-account-group'
        case 'dynamic_chat' : return 'mdi-bank'
        default : return 'mdi-account'
      }
    },

    async getManagerChats() {

      let result = await this.GET_MANAGER_CHATS(this.selectedManager.id)
      if ( !result || result === true ) {
        return
      }
      this.items = result
    }
  },
  mounted() {
    this.getManagerChats()
  }
}
</script>

<style scoped>

</style>